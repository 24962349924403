<template>
  <!-- Text Input -->
  <div
    :id="name"
    :ref="(el) => (refs[name].value = el as HTMLInputElement)"
    class="datePicker relative flex w-full flex-col items-start space-y-px"
  >
    <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
    <slot name="activator" :is-open="isInputFocused" :is-disabled="disabled" @click="setInputFocused(true)">
      <label
        class="text-body-2 relative flex h-10 w-full flex-row items-center justify-between gap-2 rounded-xl bg-white px-4 py-3"
        :class="disabled ? 'cursor-default' : 'cursor-pointer'"
        @focus="disabled ? null : setInputFocused(true)"
        @blur="disabled ? null : setInputFocused(false)"
        @click="disabled ? null : setInputFocused(true)"
        @input="input($event)"
      >
        <UiIcon
          v-if="showIcon"
          name="calendar"
          :class="[
            error ? 'text-black-60' : isInputFocused ? 'text-primary-120' : 'text-black-60',
            { 'z-10': disabled },
          ]"
          class="peer/icon-prefix"
        />

        <input
          :id="name"
          :name="name"
          :value="inputValue"
          :text="inputValue"
          type="text"
          autocomplete="off"
          :placeholder="placeholder"
          :disabled="disabled"
          readonly
          class="peer h-[20px] w-full flex-1 border-none bg-transparent outline-none placeholder:text-sm placeholder:font-normal placeholder:leading-5 disabled:z-10 disabled:text-black-60"
          :class="{ 'placeholder:text-black-40': error }"
          @focus="setInputFocused(true)"
        />

        <!-- need for QA tests -->
        <input
          :id="`autotest_${name}`"
          :value="
            isInputUntouched && startWithPlaceholder
              ? null
              : isRange
              ? vModel?.start && vModel?.end
                ? `${format(vModel.start, dateFormat)} - ${format(vModel.end, dateFormat)}`
                : null
              : format(vModel, dateFormat)
          "
          type="text"
          class="hidden"
        />
        <!-- need for QA tests -->

        <UiIcon
          v-if="!hideSuffix"
          name="chevron-big-filled-down"
          :class="[
            error ? 'text-black-60' : 'text-black-60 peer-focus-visible:text-primary-120',
            { 'rotate-180': isInputFocused },
          ]"
          class="peer/icon transition-all duration-200"
        />
        <div
          class="absolute left-0 size-full rounded-xl border-[1.5px] border-solid outline-none transition-colors duration-200"
          :class="[
            error
              ? 'border-error-100 peer-hover/icon-prefix:border-error-100 peer-hover/icon:border-error-100 peer-hover/prefix:border-error-100 peer-hover/suffix:border-error-100 peer-hover:border-error-100 '
              : 'border-black-20 hover:border-primary-50 active:border-primary-120 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50  peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05 ',
            isInputFocused ? 'border-primary-120' : '',
          ]"
        ></div>
      </label>
    </slot>
    <transition name="fade">
      <div
        v-show="isInputFocused"
        :id="`${name}_date_picker`"
        class="calendar fixed z-20 min-w-[200px] rounded-xl border-[1.5px] border-solid border-primary-10 bg-white px-1 py-2"
        :class="completeMode ? 'overflow-hidden !rounded-3xl' : ''"
      >
        <div v-if="!completeMode">
          <ClientOnly>
            <DatePicker
              v-if="!isRange"
              v-model="vModel"
              title-position="left"
              :max-date="maxDate"
              :min-date="minDate"
              expanded
              :locale="{ masks: { weekdays: 'WW' } }"
              @update:model-value="select"
              @click.stop.prevent="updateStyle"
            />
            <DatePicker
              v-else
              v-model.range="vModel"
              title-position="left"
              :max-date="maxDate"
              :min-date="minDate"
              :locale="{ masks: { weekdays: 'WW' } }"
              expanded
              @update:model-value="select"
              @click.stop.prevent="updateStyle"
            />
          </ClientOnly>
        </div>
        <div v-else @click.stop.prevent>
          <div class="flex h-full">
            <div class="h-full border-r border-black-10">
              <ul class="py-2 pb-[55px]">
                <li
                  v-for="period of timePeriods"
                  :key="period.id"
                  class="text-body-2 h-10 cursor-pointer px-3 py-[10px]"
                  :class="{ 'text-primary-100': activePeriod === period.value }"
                  @click.stop.prevent="period.changeValue()"
                >
                  {{ period.value }}
                </li>
              </ul>
              <div
                class="flex items-center justify-center border-t border-black-10 p-4"
                @click.stop.prevent="clearParams"
              >
                <UiButtonBase id="clear_all" type="secondary" size="big">Clear all</UiButtonBase>
              </div>
            </div>
            <div>
              <DatePicker
                :key="datePickerKey"
                v-model.range="shallowValue"
                :columns="2"
                title-position="left"
                :max-date="maxDate"
                :min-date="minDate"
                :first-day-of-week="2"
                :locale="{ masks: { weekdays: 'WW' } }"
                expanded
                class="px-2"
                @update:model-value="select($event, true)"
                @click.stop.prevent="updateStyle"
              />

              <div class="flex items-center border-t border-black-10 p-4">
                <div class="flex gap-x-8 px-4">
                  <div class="flex w-36 items-center gap-x-2">
                    <span class="text-[14px] text-black-60">from: </span>
                    <UiInputMenu
                      v-model="chooseTime.start"
                      :items="timeOptions"
                      name="activity_start_hour"
                      :width="100"
                      @update:model-value="setTime()"
                    >
                      <template #activator="{ onClick, isOpen }">
                        <UiButtonGhost id="start_time" @click="onClick">
                          {{
                            timeOptions.find((o) => JSON.stringify(o.value) === JSON.stringify(chooseTime.start))?.text
                          }}
                          <UiIcon
                            name="chevron-big-filled-down"
                            :class="[{ 'rotate-180': isOpen }, 'transition-all duration-200']"
                          ></UiIcon>
                        </UiButtonGhost>
                      </template>
                    </UiInputMenu>
                  </div>
                  <div class="flex w-36 items-center gap-x-2">
                    <span class="text-[14px] text-black-60"> to: </span>
                    <UiInputMenu
                      v-model="chooseTime.end"
                      :items="endTimeOptions"
                      name="activity_end_hour"
                      :width="100"
                      @update:model-value="setTime()"
                    >
                      <template #activator="{ onClick, isOpen }">
                        <UiButtonGhost id="end_time" @click="onClick">
                          {{
                            [...timeOptions, ...endTimeOptions].find(
                              (o) => JSON.stringify(o.value) === JSON.stringify(chooseTime.end)
                            )?.text
                          }}
                          <UiIcon
                            name="chevron-big-filled-down"
                            :class="[{ 'rotate-180': isOpen }, 'transition-all duration-200']"
                          ></UiIcon>
                        </UiButtonGhost>
                      </template>
                    </UiInputMenu>
                  </div>
                </div>
                <div @click.stop.prevent="applyParams()">
                  <UiButtonBase id="apply" size="big">
                    <div class="px-7">Apply</div>
                  </UiButtonBase>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="absolute -bottom-4 h-4 w-full">
      <transition name="fade" mode="out-in">
        <p v-if="error" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100">
          {{ error }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  format,
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  sub,
  subMonths,
} from 'date-fns'
import 'v-calendar/style.css'
import { DatePicker } from 'v-calendar'
import { onClickOutside } from '@vueuse/core'
import type { DateRange } from '@/types'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: any
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  error?: string
  alignRight?: boolean
  isRange?: boolean
  completeMode?: boolean
  maxDate?: Date
  minDate?: Date
  startWithPlaceholder?: boolean
  showIcon?: boolean
  hideSuffix?: boolean
  dateFormat?: string
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  label: '',
  placeholder: '',
  error: '',
  maxDate: undefined,
  minDate: undefined,
  startWithPlaceholder: true,
  showIcon: true,
  hideSuffix: false,
  dateFormat: 'dd/MM/yyyy',
})

const datePickerKey = ref(0)
const isInputFocused = ref<boolean>(false)
const isInputUntouched = ref<boolean>(true)
const shallowValue = ref<string | string[]>(JSON.parse(JSON.stringify(props.modelValue)))

const inputValue = computed(() =>
  isInputUntouched.value && props.startWithPlaceholder
    ? null
    : props.isRange
    ? vModel.value?.start && vModel.value?.end
      ? `${format(vModel.value.start, props.dateFormat)} - ${format(vModel.value.end, props.dateFormat)}`
      : null
    : format(vModel.value, props.dateFormat)
)

watch(
  () => props.modelValue,
  (value) => {
    if (props.isRange) {
      shallowValue.value =
        value?.start && value?.end
          ? {
              start: startOfDay(new Date(value?.start)),
              end: endOfDay(new Date(value?.end)),
            }
          : { start: undefined, end: undefined }
    } else {
      shallowValue.value = value ? new Date(value) : new Date()
    }
  },
  { immediate: true }
)

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}

const vModel = computed({
  get() {
    if (props.isRange) {
      return props.modelValue?.start && props.modelValue?.end
        ? {
            start: new Date(props.modelValue.start),
            end: new Date(props.modelValue.end),
          }
        : { start: undefined, end: undefined }
    } else {
      return props.modelValue ? new Date(props.modelValue) : new Date()
    }
  },
  set(value: Date | DateRange) {
    if (props.isRange) {
      emits('update:modelValue', {
        start: value.start ? (value as DateRange).start.toISOString() : undefined,
        end: value.end ? (value as DateRange).end.toISOString() : undefined,
      })
    } else {
      if (!value) value = new Date()
      emits('update:modelValue', new Date(value as Date).toISOString())
    }
  },
})

const timeOptions = (() => {
  const result = []
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes = minutes + 15) {
      result.push({
        text: `${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${hours >= 12 ? 'PM' : 'AM'}`,
        value: { hours, minutes },
      })
    }
  }
  return result
})()

const endTimeOptions = ref(
  timeOptions.concat({
    text: '24:00 PM',
    value: { hours: 23, minutes: 59 },
  })
)

const chooseTime = ref({
  start: {
    hours: shallowValue.value?.start?.getHours() || timeOptions[0].value.hours,
    minutes: shallowValue.value?.start?.getMinutes() || timeOptions[0].value.minutes,
  },
  end:
    new Date(shallowValue.value?.end).getTime() === new Date(endOfDay(shallowValue.value?.end)).getTime()
      ? {
          hours: 23,
          minutes: 59,
        }
      : {
          hours: shallowValue.value?.end?.getHours() || 23,
          minutes: shallowValue.value?.end?.getMinutes() || 59,
        },
})

const activePeriod = ref()
const timePeriods = ref([
  {
    id: 0,
    value: 'Today',
    changeValue: () => {
      const dateRange = {
        start: startOfToday(),
        end: endOfToday(),
      }
      select(dateRange)
      activePeriod.value = 'Today'
    },
  },
  {
    id: 1,
    value: 'Yesterday',
    changeValue: () => {
      const dateRange = {
        start: startOfYesterday(),
        end: endOfYesterday(),
      }
      select(dateRange)
      activePeriod.value = 'Yesterday'
    },
  },
  {
    id: 2,
    value: 'Last 7 days',
    changeValue: () => {
      const dateRange = {
        start: sub(new Date(), { days: 7 }),
        end: endOfToday(),
      }
      select(dateRange)
      activePeriod.value = 'Last 7 days'
    },
  },
  {
    id: 3,
    value: 'Last 30 days',
    changeValue: () => {
      const dateRange = {
        start: sub(new Date(), { days: 30 }),
        end: endOfToday(),
      }
      select(dateRange)
      activePeriod.value = 'Last 30 days'
    },
  },
  {
    id: 4,
    value: 'This month',
    changeValue: () => {
      const dateRange = {
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      }
      select(dateRange)
      activePeriod.value = 'This month'
    },
  },
  {
    id: 5,
    value: 'Last month',
    changeValue: () => {
      const dateRange = {
        start: startOfMonth(subMonths(new Date(), 1)),
        end: endOfMonth(subMonths(new Date(), 1)),
      }
      select(dateRange)
      activePeriod.value = 'Last month'
    },
  },
  {
    id: 6,
    value: 'Custom',
    changeValue: () => {
      activePeriod.value = 'Custom'
    },
  },
])

const calculatePosition = () => {
  if (!isInputFocused.value) return
  const viewportOffset = refs[props.name].value?.getBoundingClientRect()
  if (viewportOffset) {
    // Here we get the element position to the viewport
    const top = viewportOffset.top
    const left = viewportOffset.left

    //   Then we set the position of the menu to those coordinates (if theres space)
    //   We do this because we use Teleport to transport to the body of the page
    //   Because otherwise, the menu would be clipped by tables, forms, etc
    //   Source: Trust me
    setTimeout(() => {
      const select = document.getElementById(`${props.name}_date_picker`)

      if (select) {
        const spaceToBottom = window.innerHeight - top
        if (!props.completeMode && spaceToBottom < select.offsetHeight + Number(refs[props.name].value?.offsetHeight)) {
          select.style.top = `${top - select.offsetHeight}px`
        } else {
          select.style.top = `${top + Number(refs[props.name].value?.offsetHeight)}px`
        }
        const spaceToRight = window.innerWidth - left

        const width = Number(select.offsetWidth)

        if (spaceToRight < width || props.alignRight) {
          select.style.left = `${left - width + Number(refs[props.name].value?.offsetWidth)}px`
        } else {
          select.style.left = `${left}px`
        }

        select.style.width = `${width}px`
      }
    })
  }
}

const setInputFocused = (value: boolean) => {
  if (props.disabled) return
  isInputFocused.value = value
  calculatePosition()
}

const input = (event: Event) => {
  isInputFocused.value = true
  shallowValue.value = (event.target as HTMLInputElement).value
}

let observer: MutationObserver
const options = {
  subtree: true,
  childList: true,
  attributes: true,
}

const updateStyle = () => {
  const domElement = document.querySelector('.calendar')
  if (domElement) {
    const vcArrow = domElement?.querySelectorAll('.vc-arrow')
    const vcWeeks = domElement?.querySelectorAll('.vc-weeks')
    const vcPopoverCaret = domElement?.querySelectorAll('.vc-popover-caret')
    const vcNavContainer = domElement?.querySelectorAll('.vc-nav-container')
    if (vcPopoverCaret.length) {
      vcArrow.forEach((element) => (element.style.display = 'none'))
    } else {
      vcArrow.forEach((element) => (element.style.display = 'block'))
    }
    if (vcNavContainer.length) {
      vcWeeks.forEach((element) => (element.style.display = 'none'))
    } else {
      vcWeeks.forEach((element) => (element.style.display = 'block'))
    }
  }
}

onMounted(() => {
  onClickOutside(refs[props.name], (event) => {
    if (!isInputFocused.value) return
    const datePicker = document.getElementById(`${props.name}_date_picker`)
    if (datePicker) {
      // Here we get the element position to the viewport
      const top = datePicker.offsetTop
      const left = datePicker.offsetLeft
      const right = left + datePicker.offsetWidth
      const bottom = top + datePicker.offsetHeight

      const clickedInHourPicker = event.x > left && event.x < right && event.y > top && event.button < bottom

      if (!clickedInHourPicker) {
        if (isInputFocused.value) isInputFocused.value = false
        shallowValue.value = JSON.parse(JSON.stringify(props.modelValue))
      }
    }
  })
  window.addEventListener('scroll', calculatePosition, true)
  const target = document.querySelector('.datePicker')
  observer = new MutationObserver(() => {
    updateStyle()
  })
  if (target) observer.observe(target, options)
})

onUnmounted(() => {
  window.removeEventListener('scroll', calculatePosition)
  if (observer) observer.disconnect()
})

const applyParams = () => {
  vModel.value = shallowValue.value
  setTimeout(() => {
    isInputFocused.value = false
    isInputUntouched.value = false
  })
}

const clearParams = () => {
  select()
  chooseTime.value = {
    start: {
      hours: timeOptions[0].value.hours,
      minutes: timeOptions[0].value.minutes,
    },
    end: {
      hours: endTimeOptions.value[endTimeOptions.value.length - 1].value.hours,
      minutes: endTimeOptions.value[endTimeOptions.value.length - 1].value.minutes,
    },
  }
  activePeriod.value = null
}

const select = (value?: DateRange, isCustom = false) => {
  if (props.completeMode) {
    if (isCustom) activePeriod.value = 'Custom'
    if (!shallowValue.value) {
      shallowValue.value = {
        start: undefined,
        end: undefined,
      }
    }

    shallowValue.value.start = value?.start
    shallowValue.value.end = value?.end
    setTime()
    datePickerKey.value++
  } else {
    isInputFocused.value = false
    isInputUntouched.value = false
  }
}

const setTime = () => {
  if (!shallowValue.value?.start || !shallowValue.value?.end) return
  const start = new Date(shallowValue.value.start)
  const end = new Date(shallowValue.value.end)

  shallowValue.value.start = chooseTime.value.start.hours
    ? new Date(start.setHours(chooseTime.value.start.hours, chooseTime.value.start.minutes))
    : startOfDay(start)

  shallowValue.value.end = chooseTime.value.end.hours
    ? new Date(end.setHours(chooseTime.value.end.hours, chooseTime.value.end.minutes))
    : endOfDay(end)
}
</script>

<style scoped lang="scss">
:deep(.vc-container) {
  border: none !important;
  .vc-weeks {
    padding-top: 32px;
  }
  .vc-week {
    margin: 8px 0;
  }
}
</style>
